import './App.css';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { getRank, generateMega } from './Calc';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const theme = createTheme();

const megaMap = {
  1: {
    1: 24800430
    , 2: 300
    , 3: 300
    , 4: 300
    , 5: 300
    , 6: 300
    , 'base': '44***324*212'
  }
  , 2: {
    1: 1200000000
    , 2: 9914580
    , 3: 112410
    , 4: 12390
    , 5: 3120
    , 6: 780
    , 'base': '133411142114'
  }
};

function toDispMoney(value) {
  return value.toLocaleString() + '円';
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://line.toroneko.com/">
        toroneko.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function toDispSalary(value) {
  return value.toLocaleString() + '円';
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function App() {
  const [salary, setSalary] = React.useState(50000);
  const [statusStr, setStatusStr] = React.useState('')
  const [megaObj, setMegaObj] = React.useState({})
  const [megaArray, setMegaArray] = React.useState([])
  const [resultObj, setResultObj] = React.useState({'status': 'none'})
  const [isDisabled, setIsDisabled] = React.useState(false);

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
  };

  const handleBtnClick = (num) => {
    setIsDisabled(true);
    setResultObj(prevState => ({
      ...prevState,
      status: 'none',
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      win: 0
    }))
    const resultObj = {
      status: 'none', 
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      win: 0
    };
    const megaObj = megaMap[num];
    setMegaObj(megaObj)
    const amount = salary;
    setStatusStr('くじを購入中です…')
    const megaArray = generateMega(amount);
    setMegaArray(megaArray)
    setStatusStr('当選確認中です…')
    resultObj.amount = amount
    let win = 0
    for (let i = 0; i < megaArray.length; i++) {
      const rank = getRank(megaObj['base'], megaArray[i]);
      if (rank) {
        win += megaObj[rank]
        resultObj[rank]++
      } else {
        resultObj[7]++
      }
    }
    resultObj.win = win
    resultObj.total = win - amount
    resultObj.status = 'done'
    setResultObj(resultObj)
    setStatusStr('')
    setIsDisabled(false);
  }

  return (
    <div className="App">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            めがシミュレーター
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Accordion sx={{ my: { xs: 3, md: 6 }}}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>ルール説明</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              経緯は省略します<br/><br/>
              あついやつ：<a href="https://store.toto-dream.com/dcs/subos/screen/pi05/spin014/PGSPIN01401LnkHoldCntLotResultLstBIG.form?popupDispDiv=disp&holdCntId=1476#MEGABigank" target="_blank" rel="noreferrer">第1476回　MEGA BIG</a>:<br/>
              当選番号：44***324*212<br/>
              (*は当たり確定)<br/>
              1等：24,800,430円<br/>
              2等：300円<br/>
              3等：300円<br/>
              4等：300円<br/>
              5等：300円<br/>
              6等：300円<br/>
              普段のやつ：<a href="https://store.toto-dream.com/dcs/subos/screen/pi05/spin014/PGSPIN01401LnkHoldCntLotResultLstBIG.form?popupDispDiv=disp&holdCntId=1463#MEGABigank" target="_blank" rel="noreferrer">第1463回　MEGA BIG</a>:<br/>
              当選番号：133411142114<br/>
              1等：1,200,000,000円<br/>
              2等：9,914,580円<br/>
              3等：112,410円<br/>
              4等：12,390円<br/>
              5等：3,120円<br/>
              6等：780円<br/>
              <br/>
              300円で1口として、入れた金額の分のくじを買って、当選金額を出します
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
          </Typography>
          <React.Fragment>
            {/*
            <Typography variant="h6" gutterBottom>
              Shipping address
            </Typography>
            */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="salary"
                  name="salary"
                  label="購入金額"
                  fullWidth
                  autoComplete="購入金額"
                  variant="standard"
                  value={salary}
                  onChange={handleSalaryChange}
                />
              </Grid>
            </Grid>
            {
            <Box>
              <Button
                id="btn1"
                size="large"
                variant="contained"
                onClick={() => handleBtnClick(1)}
                sx={{ mt: 3, ml: 1 }}
                disabled={isDisabled}
               >
                あついやつを買う！
              </Button>
              <Button
                id="btn2"
                size="large"
                variant="contained"
                onClick={() => handleBtnClick(2)}
                sx={{ mt: 3, ml: 1 }}
                disabled={isDisabled}
              >
                普段のやつを買う！
              </Button>
            </Box>
            }
          </React.Fragment>
        </Paper>
        <Grid item xs={12} md={4} lg={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              結果
            </Typography>
            <Typography component="h2" variant="h6" gutterBottom>
              {statusStr}
            </Typography>
            {resultObj.status !== 'none' && (
              <>
              <Typography component="p">
                購入枚数：{megaArray.length}枚
              </Typography>
              <Typography component="p">
                払い戻し金額：{toDispMoney(resultObj.win)}
              </Typography>
              <Typography component="p" variant='h4'>
                損益：{toDispMoney(resultObj.total)}
              </Typography>
              <Typography>
                1等：{resultObj[1]}本({toDispMoney(resultObj[1] * megaObj[1])})<br/>
                2等：{resultObj[2]}本({toDispMoney(resultObj[2] * megaObj[2])})<br/>
                3等：{resultObj[3]}本({toDispMoney(resultObj[3] * megaObj[3])})<br/>
                4等：{resultObj[4]}本({toDispMoney(resultObj[4] * megaObj[4])})<br/>
                5等：{resultObj[5]}本({toDispMoney(resultObj[5] * megaObj[5])})<br/>
                6等：{resultObj[6]}本({toDispMoney(resultObj[6] * megaObj[6])})<br/>
                ハズレ：{resultObj[7]}本
              </Typography>
              <Accordion sx={{ my: { xs: 3, md: 6 }}}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>購入したくじ番号</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    当選番号：<br/><strong>{megaObj.base}</strong>
                    <br/>
                    {megaArray.map((item, index) => (
                      <React.Fragment key={index}>
                        <br />
                        {item}
                      </React.Fragment>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </>
            )}
          </Paper>
        </Grid>
        <Copyright />
      </Container>
    </ThemeProvider>
    </div>
  );
}

export default App;
