const BLOCK = 1;
export const generateMega = (amount) => {
  const cnt = Math.floor(amount / 300)
  const result = [];
  for (let j = 0; j < cnt; j++) {
    let mega = '';
    const characters = '1234';
    const length = 12;
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        mega += characters[randomIndex];
    }
    result.push(mega);
  }
  return result
}
export const getRank = (baseNum, curNum) => {
  if (baseNum.length !== 12 || curNum.length !== 12) {
    throw new Error('Both strings must be exactly 12 characters long.');
  }
  let mismatchCount = 0;
  for (let i = 0; i < 12; i++) {
      if (baseNum[i] !== curNum[i] && baseNum[i] !== '*' && curNum[i] !== '*') {
          mismatchCount++;
      }
  }
  if (mismatchCount >= 0 && mismatchCount <= 5) {
    return mismatchCount + 1
  } else {
    return null
  }
}
